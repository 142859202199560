import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'

import useBreakpoint from 'hooks/useBreakpoint'

import Layout from 'components/Layout'
import SEO from 'components/SEO'
import ProductItem from 'components/ProductItem'

import clothingLinks from './clothing.json'
import accessoriesLinks from './accessories.json'
import homeLinks from './home.json'
import littleOnesLinks from './little-ones.json'
import christmasLinks from './christmas.json'
import houndLinks from './hound.json'

import s from './styles.module.css'

const Collection = ({ data, pageContext }) => {
  const breakpoint = useBreakpoint()
  const collection = data.shopifyCollection
  const { products, title, description, descriptionHtml } = collection
  const { handle } = pageContext

  const decideMenuItems = (col) => {
    const match = col.shopifyId.replace('gid://shopify/Collection/', '')
    switch (true) {
      case ['168566947887'].includes(match):
        return clothingLinks
      case [
        '446209589539',
        '465860165923',
        '446100439331',
        '446209884451',
        '446474780963',
        '446209949987',
        '446209458467',
        '668452520320',
        '668452487552',
        '667438121344',
      ].includes(match):
        return accessoriesLinks
      case [
        '446468882723',
        '462113308963',
        '446469275939',
        '446469374243',
      ].includes(match):
        return homeLinks
      case ['168567078959'].includes(match):
        return littleOnesLinks
      case ['446212145443', '446212243747', '446212342051'].includes(match):
        return houndLinks
      case [
        '267719147567',
        '168576745519',
        '168576712751',
        '168664432687',
      ].includes(match):
        return christmasLinks
      case ['168573468719'].includes(match):
        return []
      default:
        return clothingLinks
    }
  }
  const menuItems = decideMenuItems(collection)

  const limit = useCallback((bp) => {
    switch (bp) {
      case 'xs':
        return 4
      case 'sm':
        return 8
      case 'md':
        return 8
      case 'lg':
        return 12
      default:
        return 12
    }
  }, [])

  const [hasMore, setMore] = useState(products.length > limit(breakpoint))
  const [productsList, addProducts] = useState([
    ...products.slice(0, limit(breakpoint)),
  ])

  const loadProducts = useCallback(() => {
    const currentLength = productsList.length
    const more = currentLength < products.length
    const nextProducts = more
      ? products.slice(currentLength, currentLength + limit(breakpoint))
      : []
    setMore(more)
    addProducts([...productsList, ...nextProducts])
  }, [products, productsList, limit, breakpoint])

  const handleScroll = useCallback(() => {
    if (!hasMore) return
    if (
      window &&
      window.innerHeight * 2 + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight
    ) {
      loadProducts()
    }
  }, [hasMore, loadProducts])

  useEffect(() => {
    window?.addEventListener('scroll', handleScroll)
    window?.addEventListener('resize', handleScroll)

    return () => {
      window?.removeEventListener('scroll', handleScroll)
      window?.removeEventListener('resize', handleScroll)
    }
  }, [handleScroll])

  return (
    <Layout className={s.container} {...pageContext}>
      <Helmet>
        <html data-theme-color="ebb" />
      </Helmet>
      <SEO title={`${title} | Chalk UK`} description={description} />
      <div className={s.header}>
        <h1 className={s.title}>{title}</h1>
        {descriptionHtml && (
          <div
            className={s.description}
            dangerouslySetInnerHTML={{ __html: descriptionHtml }}
          />
        )}
      </div>
      <nav className={s.menu}>
        {menuItems?.links?.map(({ label, slug }) => {
          return (
            <Link
              key={label}
              className={classNames(s.link, {
                [s.linkActive]: handle === slug,
              })}
              to={`/collection/${slug}`}
            >
              {label}
            </Link>
          )
        })}
      </nav>
      <div className={s.products} data-cy="products">
        {productsList.length
          ? productsList.map((p) => <ProductItem key={p.id} data={p} />)
          : 'No products available'}
      </div>
    </Layout>
  )
}

Collection.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
}

export default Collection

export const pageQuery = graphql`
  query ShopifyCollectionByHandle($handle: String!) {
    shopifyCollection(handle: { eq: $handle }) {
      shopifyId
      title
      description
      descriptionHtml
      products {
        id
        title
        handle
        images {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 640
                placeholder: NONE
                sizes: "(max-width: 900px) 250px, (max-width: 1920px) 370px, (max-width: 6000px) 640px"
                breakpoints: [250, 370, 640]
                aspectRatio: 0.75
              )
            }
          }
        }
        metafields {
          key
          value
          type
        }
        variants {
          metafields {
            key
            value
            type
          }
        }
      }
    }
  }
`
